import React from 'react';
import { Section, Link, Layout, Logo } from 'components';

import './thankyou.scss';

const Thankyou = () => (
  <Layout>
    <Section className="thank-you-page">
      <Logo className="thank-you-logo" icon />
      <h1 className="thank-you-title">Thank you :)</h1>
      <h3 className="thank-you-subtitle">A member of our team will be in touch with you shortly</h3>
      <div className="thank-you-button-container">
        <Link className="button thank-you-cta" to="/">
          Back to home
        </Link>
      </div>
    </Section>
  </Layout>
);

export default Thankyou;
